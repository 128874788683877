@import 'variables';
$font-bmw-condensed: 'BMWGroup Condensed';

/* latin */
@font-face {
  font-family: $font-face;
  font-weight: 100 200;
  src: url('./../../fonts/BMWGroupTNTT-Thin.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-face;
  font-weight: 300;
  src: url('./../../fonts/BMWGroupTNTT-Light.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: $font-face;
  font-weight: 400;
  src: url('./../../fonts/BMWGroupTNTT-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-face;
  font-weight: 500 600;
  src: url('./../../fonts/BMWGroupTNTT-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-face;
  font-weight: 700 900;
  src: url('./../../fonts/BMWGroupTNTT-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-bmw-condensed;
  font-weight: 100 200;
  src: url('./../../fonts/BMWGroupTNCondensedTT-Thin.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-bmw-condensed;
  font-weight: 300;
  src: url('./../../fonts/BMWGroupTNCondensedTT-Light.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: $font-bmw-condensed;
  font-weight: 400;
  src: url('./../../fonts/BMWGroupTNCondensedTT-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-bmw-condensed;
  font-weight: 500 600;
  src: url('./../../fonts/BMWGroupTNCondensedTT-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: $font-bmw-condensed;
  font-weight: 700 900;
  src: url('./../../fonts/BMWGroupTNCondensedTT-Bold.ttf') format('truetype');
  font-display: swap;
}
